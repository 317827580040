import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import { NavBar } from "../components/nav-bar";
import { Footer } from "../components/Footer/index";
import Image1 from "../images/bi/image1.png";
import Image2 from "../images/bi/image2.png";
import Image3 from "../images/bi/image3.png";
import Image4 from "../images/bi/image4.png";
import Banner from "../images/bi/banner.png";
import EnImage1 from "../images/bi/enimage1.png";
import EnImage2 from "../images/bi/enimage2.png";
import EnImage3 from "../images/bi/enimage3.png";
import EnImage4 from "../images/bi/enimage4.png";
import EnBanner from "../images/bi/enbanner.png";
import { graphql, StaticQuery } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import BibgPNG from "../images/img/bibg.png";

export default () => {
  const { t, i18n } = useTranslation();
  const { languages, changeLanguage } = useI18next();
  return (
    <Layout>
      <Container bg={BibgPNG}>
        <NavBar main />
        <Wrap>
          <img width={960} src={i18n.language === "zh" ? Banner : EnBanner} alt="" />
        </Wrap>
      </Container>
      <Video>
        {i18n.language === "zh" && <Title style={{ width: "900px" }}>1. {t("bititle1")}</Title>}
        {i18n.language === "en" && <Title style={{ width: "700px" }}>1. {t("bititle1")}</Title>}
        <VideoWrap src={i18n.language === "zh" ? Image1 : EnImage1} />
      </Video>
      <Main>
        <Title>2. {t("bititle2")}</Title>
        {i18n.language === "zh" && (
          <SubTitleWrap>
            <SubTitle>
              核心模块下有多个次级模块，涵盖了购物中心、写字楼、酒店、园区、物流、销售和综合体七大板块;
            </SubTitle>
            <SubTitle>
              AMSS智能资产管理体系通过多个独立模块的组合，实现了轻便灵活的顶层管理体系，可分业务板块或分项目阶段灵活与企业现有
              <br />
              管理体系进行数据对接，实现管理指标监控。
            </SubTitle>
          </SubTitleWrap>
        )}
        <img width={1180} height={"auto"} src={i18n.language === "zh" ? Image2 : EnImage2} alt="" />
        <img
          src={i18n.language === "zh" ? Image3 : EnImage3}
          width={"1130px"}
          style={{ marginTop: "30px", marginBottom: "60px" }}
          alt=""
        />
        <Title>3. {t("bititle3")}</Title>
        <img src={i18n.language === "zh" ? Image4 : EnImage4} width={"1130px"} alt="" />
      </Main>
      <Footer />
    </Layout>
  );
};
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Wrap = styled.div`
  margin-top: 40px;
`;

const Container = styled.div`
  min-width: 1440px;
  width: 100%;
  display: flex;
  padding-bottom: 100px;
  flex-direction: column;
  align-items: center;
  background-image: ${props => `url(${props.bg})`};
  background-repeat: repeat;
  background-size: cover;
`;
const VideoWrap = styled.img`
  width: 728px;
  background-color: #252525;
`;

const Main = styled.div`
  padding-top: 46px;
  padding-bottom: 53px;
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
`;
const Video = styled(Main)`
  background-color: #eaebf3;
`;

const Title = styled.h2`
  padding-bottom: 30px;
  font-size: 21px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  text-align: center;
  color: #2f3852;
`;

const SubTitleWrap = styled.div`
  width: 922px;
  margin-bottom: 40px;
`;
const SubTitle = styled.h3`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.81;
  color: #2f3852;
  position: relative;
  &:before {
    content: "";
    width: 5px;
    height: 5px;
    background-color: #2f3852;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: 12px;
  }
`;
